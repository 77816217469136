(function (w: any) {
    w.listeners = [];
    w.Answers = {
        currentPage: '100',
        onPageLoad: (func) => w.listeners.push(func),
        onContactFormFieldsChanged: () => { },
        setContactFormData: () => { },
        setCustomFieldsConfiguration: () => { },
        setShowCCField: () => { },
        setShowPartnerCompanyField: () => { },
    };
})(window);
